<template>
    <v-container>
        <base-material-card icon="mdi-factory" title="Documentos" class="px-5 py-3">
            <v-row>
                <v-col cols="12">
                    <v-btn color="primary" @click="$router.push({name: 'DocumentoCreate'})">Nuevo</v-btn>
                    <v-btn color="primary" @click="downloadExcel">Descargar</v-btn>
                </v-col>
                <v-col cols="6">
                    <v-menu v-model="menu_fecha_inicio" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="pagination.fecha_inicial" label="Fecha inicial" readonly v-on="on" :error-messages="errors.fecha_inicial" clearable></v-text-field>
                        </template>
                        <v-date-picker v-model="pagination.fecha_inicial" @input="menu_fecha_inicio = false; load()" locale="es-MX"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="6">
                    <v-menu v-model="menu_fecha_final" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="pagination.fecha_final" label="Fecha final" readonly v-on="on" :error-messages="errors.fecha_final" clearable></v-text-field>
                        </template>
                        <v-date-picker v-model="pagination.fecha_final" @input="menu_fecha_final = false; load()" locale="es-MX"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="6">
                    <v-select v-model="pagination.vendedor_id" @change="load()" :items="vendedores" label="Vendedor" item-value="id" item-text="razon_social" clearable :error-messages="errors.vendedor_id"></v-select>
                </v-col>
                <v-col cols="6">
                    <v-text-field v-model="pagination.id" label="ID" @input="load"></v-text-field>
                </v-col>
            </v-row>
        </base-material-card>
        <v-card>
            <v-card-text>
                <v-data-table :loading="loading" :headers="headers" :items="documentos" hide-default-footer disable-pagination show-expand>
                    <template v-slot:item.monto="{item}">
                        {{formatoMoneda(item.monto)}}
                    </template>
                    <template v-slot:item.saldo="{item}">
                        <span :class="{'red--text' : item.saldo}">
                            {{formatoMoneda(item.saldo)}}
                        </span>
                    </template>
                    <template v-slot:item.status="{item}">
                        {{item.status}}
                    </template>
                    <template v-slot:item.acciones="{item}">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn fab icon x-small v-on="on" @click="$router.push({name: 'DocumentoMovimientos', params: {id: item.id}})" :class="{'black--text': item.movimientos_pendientes_count}">
                                    <v-icon>far fa-file-alt</v-icon>
                                </v-btn>
                            </template>
                            <span>Movimientos</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn fab icon x-small v-on="on" @click="createConvenio(item)" v-if="item.saldo > 0">
                                    <v-icon>far fa-handshake</v-icon>
                                </v-btn>
                            </template>
                            <span>Convenio</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                            {{item.observaciones}}
                        </td>
                    </template>
                </v-data-table>
                <v-col col="12">
                    <v-pagination v-model="pagination.page" class="my-4" :length="pagination.last_page" @input="load" :total-visible="10" color="primary"></v-pagination>
                </v-col>
            </v-card-text>
        </v-card>
        <v-dialog v-model="dialogConvenio" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">Convenio</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field :value="documento.saldo" label="Monto" disabled></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-file-input v-model="convenio.imagenes_files" :error-messages="errors.imagenes_files" label="Evidencias" class="required" multiple></v-file-input>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea v-model="convenio.comentario" :error-messages="errors.comentario" label="Comentario" class="required"></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :loading="loading" @click="dialogConvenio = false">Cancelar</v-btn>
                    <v-btn color="primary" :loading="loading" @click="saveConvenio">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
    export default {
        components: {
        },
        data: () => ({
            errors: {},
            headers: [
                { text: 'ID', value: 'id', sortable: false },
                { text: 'Fecha', value: 'fecha', sortable: false },
                { text: 'Monto', value: 'monto', sortable: false },
                { text: 'Saldo', value: 'saldo', sortable: false },
                { text: 'Status', value: 'status', sortable: false },
                { text: 'Vendedor', value: 'vendedor.razon_social', sortable: false },
                { text: 'Acciones', value: 'acciones', sortable: false },
            ],
            vendedores: [],
            documentos: [],
            pagination: {
                perPage: 10,
                last_page: 1,
                page: 1
            },
            menu_fecha_inicio: false,
            menu_fecha_final: false,
            loading: false,
            documento: {},
            dialogConvenio: false,
            convenio: {}
        }),
        mounted() {
            this.setDates()
            this.load()
            this.loadVendedores()
        },
        methods: {
            create() {
                this.meta_id = null
                this.showForm = true
            },
            edit(id) {
                this.meta_id = id
                this.showForm = true
            },
            loadVendedores() {
                this.$http.get(`/vendedores/all`).then(response => {
                    this.vendedores = response.data
                })
            },
            load() {
                this.loading = true
                this.errors = {}
                this.$http.get(`/documentos/`, {
                    params: this.pagination
                }).then(response => {
                    this.documentos = response.data.data.map(documento => {
                        switch (documento.status) {
                            case 'activo':
                                documento.status = 'Activo'
                                break;
                            case 'liquidado':
                                documento.status = 'Liquidado'
                                break;

                            default:
                                break;
                        }
                        return documento
                    })
                    this.pagination.last_page = response.data.last_page
                }).catch(error => {
                    switch (error.response.status) {
                        case 422:
                            this.errors = error.response.data.errors
                            break;

                        default:
                            break;
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            setDates() {
                this.pagination.fecha_inicial = moment().format('YYYY-MM-DD')
                this.pagination.fecha_final = moment().format('YYYY-MM-DD')
            },
            createConvenio(documento) {
                this.documento = documento
                this.dialogConvenio = true
                this.convenio = {}
            },
            saveConvenio() {
                this.loading = true
                this.errors = {}
                this.$http.post(`/documentos/${this.documento.id}/convenio`, this.toFormData(this.convenio)).then(response => {
                    this.dialogConvenio = false
                    this.load()
                }).catch(error => {
                    switch (error.response.status) {
                        case 422:
                            this.errors = error.response.data.errors
                            break;

                        default:
                            break;
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            toFormData(object, method) {
                let formData = new FormData

                if (method) {
                    formData.append('_method', method)
                }

                for (const key in object) {
                    if (object.hasOwnProperty(key)) {
                        const element = object[key];
                    }
                    if (object[key]) {
                        if (Array.isArray(object[key])) {
                            object[key].forEach(subobject => {
                                formData.append(`${key}[]`, subobject)
                            })
                        } else {
                            formData.append(key, object[key])
                        }
                    }
                }
                return formData
            },
            downloadExcel() {
                window.location.assign(`/documentos/excel?${jQuery.param(this.pagination)}`)
            }
        }
    }
</script>